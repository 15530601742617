import useUserAuth from '@/hooks/useUserAuth';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { SuccessfulPayment } from './SuccessfulPayment';

export interface StandaloneSuccessfulPaymentProps extends StandaloneComponentProps<typeof SuccessfulPayment> {
  headline?: ReactNode;
  caption?: ReactNode;
  description?: ReactNode;
  steps?: ReactNode[];
  contactInfo?: ReactNode;
  refreshButtonLabel?: ReactNode;
  loginButtonLabel?: ReactNode;
}

export const StandaloneArticlePaywallSuccessfulPayment: StandaloneComponent<StandaloneSuccessfulPaymentProps> = ({
  headline,
  caption,
  description,
  steps,
  contactInfo,
  refreshButtonLabel,
  loginButtonLabel,
  ...props
}) => {
  const { isUserLoggedIn } = useUserAuth();
  const router = useRouter();
  return (
    <SuccessfulPayment {...props}>
      {headline && <SuccessfulPayment.Headline>{headline}</SuccessfulPayment.Headline>}
      {caption && <SuccessfulPayment.Caption>{caption}</SuccessfulPayment.Caption>}
      {description && <SuccessfulPayment.Description>{description}</SuccessfulPayment.Description>}
      {Boolean(steps?.length) && (
        <SuccessfulPayment.Steps>
          {steps!.map((step, index) => (
            <SuccessfulPayment.Step key={index}>{step}</SuccessfulPayment.Step>
          ))}
        </SuccessfulPayment.Steps>
      )}
      {isUserLoggedIn && refreshButtonLabel && (
        <SuccessfulPayment.Button
          options={mergeOptions(
            {
              onClick: () => {
                const queryParams = new URLSearchParams(window.location.search);
                queryParams.append('refreshCode', 'true');

                router.push(`${window.location.pathname}?${queryParams}`);
              },
            },
            props.options?.$button,
          )}
        >
          {refreshButtonLabel}
        </SuccessfulPayment.Button>
      )}
      {!isUserLoggedIn && loginButtonLabel && (
        <SuccessfulPayment.Button
          href="https://login.allermedia.se/?return=https%3A%2F%2Fwww.femina.se%2F"
          options={props.options?.$button}
        >
          {loginButtonLabel}
        </SuccessfulPayment.Button>
      )}
      {contactInfo && <SuccessfulPayment.ContactInfo>{contactInfo}</SuccessfulPayment.ContactInfo>}
    </SuccessfulPayment>
  );
};
